import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  fetchProductBuildUploadedApi,
  getApplyLiveReleaseBuildAPI,
  getProductBuildIdApi
} from '@/apis/build.api';
import type {
  ApplyLiveReleaseBuild,
  Build,
  FetchProductBuildParams,
  GetApplyLiveReleaseBuildParams,
  GetDetailProductBuildId
} from '@/types/build/build.type';

export const useBuildStore = defineStore('build', () => {
  const builds = ref<Build[]>([]);
  const totalElements = ref(0);
  const applyLiveInfo = ref<ApplyLiveReleaseBuild | null>(null);

  const buildDetail = ref<Build | null>(null);
  const isRefreshFromPopup = ref(false);

  const fetchBuilds = async (params: FetchProductBuildParams) => {
    try {
      const response = await fetchProductBuildUploadedApi(params);
      if (response) {
        builds.value = response.contents || [];
        totalElements.value = response.totalElements || 0;
      }
    } catch (error) {
      // console.error(error);
      // throw error;
    }
  };

  const getApplyLiveReleaseBuild = async (params: GetApplyLiveReleaseBuildParams) => {
    try {
      const response = await getApplyLiveReleaseBuildAPI(params);

      if (response) {
        applyLiveInfo.value = response;
      }
    } catch (error) {
      applyLiveInfo.value = null;
    }
  };

  const getBuildDetail = async (params: GetDetailProductBuildId) => {
    try {
      const response = await getProductBuildIdApi(params);

      if (!response || typeof response === 'string') {
        buildDetail.value = null;
        return false;
      }

      buildDetail.value = response;
      return true;
    } catch (error) {
      buildDetail.value = null;
      return false;
    }
  };

  return {
    builds,
    totalElements,
    fetchBuilds,
    applyLiveInfo,
    getApplyLiveReleaseBuild,
    buildDetail,
    getBuildDetail,
    isRefreshFromPopup
  };
});
